<template>
  <div>
    <dashboard-page-title :showSearch="false" :showFilter="false" :showMainActions="false">
      {{ $t('auth.continueInfo') }}
    </dashboard-page-title>

     <form @submit.prevent="save">
        <b-row>
          <b-col md="8">
            <div>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-file-alt"></i> {{$t('auth.companyInfo')}}</h5>
                </template>
                <b-row>
                  <b-col  cols="4">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.ownerName"
                        validate="required"
                        :name="$t('auth.fullName')"
                        :placeholder="$t('auth.fullName')"
                       :label="$t('auth.fullName')"
                    />
                  </b-col>
                  <b-col  md="4">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.name"
                        :validate="'required'"
                        :name="$t('auth.companyName')"
                        :placeholder="$t('auth.companyName')"
                        :label="$t('auth.companyName')"
                    />
                  </b-col>
                  <!-- <b-col  md="4">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.city"
                        :validate="'required'"
                        :name="$t('auth.city')"
                        :disabled="false"
                        :placeholder="$t('auth.city')"
                        :label="$t('auth.city')"
                    />
                  </b-col> -->
                  <b-col  md="4">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.ownerNationalID"
                        :validate="{ required: true, numeric:true, digits:10,  regex: /^[1-2][0-9]*$/}"
                        :name="$t('auth.IdNumber')"
                        :disabled="false"
                        :placeholder="$t('auth.IdNumber')"
                        :label="$t('auth.IdNumber')"
                    />
                  </b-col>
                  <b-col  md="6">
                    <div class="d-flex  mb-3">
                      <input-form
                          class="mb-3 flex-1 flex-grow-1 phone-input"
                          v-model="companyInfo.phone"
                          :validate="'required'"
                          :name="$t('auth.phoneNumber')"
                          :disabled="false"
                          :placeholder="$t('auth.phoneNumber')"
                          :label="$t('auth.phoneNumber')"
                      />
                      <vue-country-code
                          v-model="companyInfo.countryCode"
                          @onSelect="onSelect"
                          :onlyCountries="['sa', 'eg']"
                          :dropdownOptions="{ disabledDialCode: true }"
                          :enabledCountryCode= true
                          defaultCountry="sa"
                          class="code-input"
                      />
                    </div>
                  </b-col>
<!--
                  <b-col  md="4">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.email"
                        :validate="'required'"
                        :name="$t('auth.email')"
                        :disabled="false"
                        :placeholder="$t('auth.email')"
                        :label="$t('auth.email')"
                        type="email"
                    />
                  </b-col> -->
                  <b-col  md="6">
                    <input-form
                        class="mb-3"
                        v-model="companyInfo.address"
                        :validate="'required'"
                        :name="$t('auth.address')"
                        :disabled="false"
                        :placeholder="$t('auth.address')"
                        :label="$t('auth.address')"
                    />
                  </b-col>
                </b-row>
              </b-card>
              <b-card class="iq-border-radius-10 mb-3">
                <template v-slot:header>
                  <h5 class="text-primary"><i class="las la-image"></i> {{ $t('auth.images') }}</h5>
                </template>
                <b-row v-if="companyInfo.status === 'processing'">
                <b-col md="12" class="mb-4" v-for="(doc, key) in combinedDocs" :key="key">
                  <cropper-images :label="doc.name" nameOfImage="image.jpg" @cropper-save="updateImageCompined($event, doc, key)"
                    :progressLoading="progressIdImage" :multi="false" :imageUrl="doc.url" />
                </b-col>
              </b-row>
              <b-row v-else-if="companyInfo.status === 'pending'">
                <b-col md="12" class="mb-4" v-for="(doc, key) in combinedDocs" :key="key">
                  <cropper-images :label="doc.name" nameOfImage="image.jpg" @cropper-save="updateImageCompined($event, doc, key)"
                    :progressLoading="progressIdImage" :multi="false" :imageUrl="doc.url" />
                </b-col>
              </b-row>
              </b-card>
            </div>
          </b-col>
          <b-col md="3">
            <b-card class="iq-border-radius-10 mb-3 main-actions">
              <template v-slot:header>
                <h5 class="text-primary">
                  <i class="las la-save"></i>
                  {{ $t('auth.publish') }}
                </h5>
              </template>
              <div v-if="loadingSubmitForm">
                <b-button variant="primary" class="w-100" disabled>
                  <spinner-loading text="يتم الحفظ"></spinner-loading>
                </b-button>
              </div>
              <div class="d-flex justify-content-end gap_1" v-else>
                  <b-button variant="primary"  class="w-100" type="submit" > {{ $t('auth.save') }} </b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </form>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
// import settingsServices from '../services/settings'
// import mainInfoMixins from '@/mixins/mainInfo'
// import imgUploadBox from '../components/imgUploadBox'
import transportationServices from '@/modules/Company/settings/services/settings'
export default {
  mounted () {
    core.index()
  },
  data () {
    return {
      requiredDocuments: [],
      combinedDocs: [],
      companyInfo: {
        ownerName: '',
        name: '',
        phone: '',
        email: '',
        city: '',
        address: '',
        ownerNationalID: '',
        company_docs: '',
        countryCode: ''
      },
      loadingSubmitForm: false,
      loadingGallery: 0,
      removeLoadingUi: false,
      progressIdImage: 0
    }
  },
  methods: {
    getRequiredDocuments () {
      transportationServices.getRequirementsDocuments().then(response => {
        this.requiredDocuments = response.data.data

        const combinedDocs = this.requiredDocuments.map(doc => {
          const driverDoc = this.companyInfo.company_docs.find(d => d.document.id === doc.id)
          return {
            ...doc,
            url: driverDoc ? driverDoc.url : null // Add URL if it exists
          }
        })
        this.combinedDocs = combinedDocs
      })
    },
    getInfo () {
      transportationServices.getCompanyInfo().then(res => {
        // this.storeActive.status = res.data.status
        this.companyInfo.name = res.data.data.name
        this.companyInfo.ownerName = res.data.data.ownerName
        this.companyInfo.phone = res.data.data.phone
        this.companyInfo.phone = res.data.data.phone
        this.companyInfo.email = res.data.data.email
        this.companyInfo.idNumber = res.data.data.idNumber
        this.companyInfo.city = res.data.data.city
        this.companyInfo.address = res.data.data.address
        this.companyInfo.ownerNationalID = res.data.data.ownerNationalID
        this.companyInfo.company_docs = res.data.data.companyDocs
        this.companyInfo.status = res.data.data.status

        this.getRequiredDocuments()
      })
    },
    updateImageCompined (file, doc, key) {
      const formData = new FormData()
      formData.append('image_name', file.imageInfo.name)
      formData.append('directory', 'Companies')
      formData.append('file', file.image)
      formData.append('app_name', 'transportation')
      const options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          this.progressIdImage = percent
        }
      }
      const fileExist = this.combinedDocs.find((ele) => {
        return ele.id === doc.id
      })
      this.commonUploadFiles(formData, options).then(res => {
        if (fileExist) {
          this.$set(this.combinedDocs, key, { ...doc, id: doc.id, url: res.data.url })
        } else {
          console.log('test no')
          this.combinedDocs.push({ document: { id: doc.id }, url: res.data.url })
        }
        this.showSuccessUploadFile()
      })
    },
    onSelect ({ name, dialCode }) {
      const countryCode = {
        20: '0020',
        966: '00966'
      }
      this.companyInfo.countryCode = countryCode[dialCode]
    },

    save () {
      this.loadingSubmitForm = true
      // Check if all required documents have images
      const missingImages = this.combinedDocs.some(doc => !doc.url)
      if (missingImages) {
        this.loadingSubmitForm = false
        core.showSnackbar('error', 'add required fields') // Display error message
        return
      }

      const newData = this.combinedDocs.map((ele) => ({ documentId: ele.id, url: ele.url }))

      // if (this.code === '00966' && this.companyInfo.phone.charAt(0) === '0') {
      //   this.fullNumber = this.companyInfo.phone.slice(1)
      // } else {
      //   this.fullNumber = this.companyInfo.phone
      // }
      const { address, city, email, name, ownerName, ownerNationalID, phone, countryCode } = this.companyInfo
      transportationServices.uploadRequiredDocsFiles({ documents: newData, status: 'processing', address, city, email, name, ownerName, ownerNationalID, phone, countryCode }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$router.push({ path: '/company/processing' })
      }).finally(() => {
        this.loadingSubmitForm = false
      })
    }
  },
  created () {
    this.getInfo()
  }
}
</script>
<style>
.main-actions {
  position: sticky !important;
  top: 0px;
}
.phone-input .form-control{
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}
.code-input{
  margin-top: 33px;
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 9px !important;
  border-top-left-radius: 9px !important;
}
</style>
